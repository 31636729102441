const Swal = window.Swal = require('sweetalert2');
const Dropzone = window.Dropzone = require("dropzone");
const jsYearCalendar = window.jsYearCalendar = require("js-year-calendar");
const Chart  = window.Chart = require('chart.js/auto');
const Sortable = window.Sortable =  require('sortablejs').Sortable;
const Inputmask = require('inputmask');
import { Calendar } from '@fullcalendar/core';
const FullCalendar = window.FullCalendar = require('fullcalendar');
const allLocales = window.allLocales = require('@fullcalendar/core/locales-all');
const DataTable = window.DataTable  = $.DataTable = require( 'datatables.net-bs5' );

import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.start();
